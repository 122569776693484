import React from "react";
import "./Community.css";
import welcomeImage from "../../../Assets/Images/welcomeImage.png";
import CustomButton from "../../../components/CustomButton/CustomButton";
import SlideShow from "../../../components/SlideShow/SlideShow";
import Cover from "../../../Assets/Committee_members/cover.png";




const Community = () => {
  return (
   
      <div className="community-container">
        <div className="SubContainer">
          <div>
          <div className=" welcomeHeading"> Welcome to our community</div>
       </div>
          <div className="welcomeDes">
            {" "}
            Our association takes pride in creating a supportive and engaging
            environment where seniors can come together, share experiences, and
            participate in meaningful activities.
          </div>
          <div className='community-button'><CustomButton type="submit" onClick={() => window.location.href='/about/#community'} label="Show more" />
</div>
          <div className="community-button-text" onClick={() => window.location.href='/about/#community'}>see more >></div>
        </div>
        <div className="welcomeImageContainer">
       <img src={Cover} alt="cover"/>
         
        </div>
        
      </div>
      
     
  );
};

export default Community;
