import React from "react";
import "./Commitee.css";
import commiteeImage1 from '../../../Assets/Images/commiteeImage1.png'
import commiteeImage2 from '../../../Assets/Images/commiteeImage2.png'
import commiteeImage3 from '../../../Assets/Images/commiteeImage3.png'
import commiteeImage4 from '../../../Assets/Images/commiteeImage4.png'

const commiteeData = [
    {
        image: commiteeImage1,
        title: 'ATSA Committee 2022_2023'

    },
    {
        image: commiteeImage2,
        title: 'ATSA Mt Druitt Branch 2022_2023'

    }
    ,
    {
        image: commiteeImage3,
        title: 'ATSA_BF Committee 2022_2023'

    },
    {
        image: commiteeImage4,
        title: 'ATSA Committee 2022_2023'

    }
]

const commiteememberData = [
    {
        position: 'Secretary ATSA',
        name: 'Mr. E. Jeyaseelan',
        address:'1 Drew street, Westmead, NSW 2145',
        address2:'+610481738530',
        email:'eajey@hotmail.com'
    },
    {
        position: 'BF Benevolent Fund',
        name: 'Mr. K . Sangarapillai ',
        address:'+610286267789',
        address2:'Mr. K. Veerasingam',
        email:'+610470618322'

    }
    ,
    {
        position: 'Treasurer ATSA',
        name: 'Mr.K.G. Sathiyasingan',
        address:'+610426005579',
        address2:'',
        email:''

    }
]
const Commitee = () => {
    return (
        <div>
            <div className="main-commitee-container" >
                <div className="commitee-heading-container" id='community'>
                    Past Commitee Members 2022/2023
                </div>
                <div className="main-commitee-image-container">
                    {
                        commiteeData.map((commitee, index) =>


                            <div className="commitee-image-container">
                                <img className="commitee-image" src={commitee.image} alt="commitee" />
                                <p className="commitee-image-des">{commitee.title}</p>
                            </div>
                        )
                    }


                </div>
                <div className="commitee-heading-container" id='community'>
                     Commitee Members 
                </div>
                <div className="main-commitee-image-container">
                    {
                        commiteememberData.map((commitee, index) =>


                            <div className="commitee-member-container">
                                <div > {commitee.position}</div>
                                <p >{commitee.name}</p>
                                <p >{commitee.address}</p>
                                <p>{commitee.address2}</p>
                                <p >{commitee.email}</p>

                            </div>
                        )
                    }


                </div>
                <div className="commitee-content">
                    <div>
                        Physical Exercise Sessions to promote health and wellbeing for our members.
                    </div>
                    <div>
                        will be held on first and third Wednesdays of every month from 09.45 am to 10.15 am at Red Byrne Community Centre
                    </div>
                    <div>
                        Please do not miss this opportunity to practice it for your well being    </div>
                </div>
            </div>




        </div>


    );
};

export default Commitee;
