import './CustomImagePreview.css'
import React, { useEffect, useState } from 'react';

function CustomImagePreview({ image }) {
  const [selectedImage, setSelectedImage] = useState(null);


  useEffect(() => {
    setSelectedImage(image);
  }, [image])
  
console.log(image);
  const handleCloseImagePreview = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      {selectedImage && (
        <div className="image-preview-modal" onClick={handleCloseImagePreview}>
          <img src={selectedImage} alt="preview" loading="lazy" />
        </div>
      )}
    </div>
  );
}

export default CustomImagePreview;