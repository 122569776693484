import React, { useState } from "react";
import "./ContactUs.css";
import CustomInput from "../../../components/Custom_Input/CustomInput";
import CustomTextArea from "../../../components/Custom_Input/CustomTextArea";
import messageIcon from "../../../Assets/Icons/messageIcon.png";
import callIcon from "../../../Assets/Icons/callIcon.png";
import locationIcon from "../../../Assets/Icons/locationIcon.png";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { connect } from 'react-redux';
import { updateFormField, resetForm, contactUsAsync } from '../../../redux/actions/contactFormActions';
import Toast from "../../../components/toast/Toast";

const ContactUs = ({ name, email, message, contact_no, updateFormField, resetForm, contactUsAsync }) => {
  const [showToast, setShowToast] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name, email, message, contact_no

  })
  const validateForm = () => {
    const errors = {};

    // Validate name
    if (!formValues.name) {
      errors.name = "Name is required";
    }

    // Validate message
    if (!formValues.message) {
      errors.message = "Message is required";
    }

    // Validate email
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      errors.email = "Invalid email address";
    }

    // Validate mobile number
    if (!formValues.contact_no) {
      errors.contact_no = "Mobile number is required";
    } 
    setFormErrors(errors);

    return errors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleShowToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000); // Adjust the timeout as needed
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidForm = validateForm();
    const areValuesNull = (obj) => {
      return Object.values(obj).every(value => value === null);
    };
    console.log(isValidForm,);
    if (areValuesNull(isValidForm)) {
      // Create formData object to send to the API
      console.log(formValues);
      handleShowToast()

      // Dispatch the async action to submit the form data
      contactUsAsync(formValues);
      setFormValues({
        name: '',
        email: '',
        message: '',
        contact_no: '',
      }); 
        }
       
  };
  return (
    <div className="mainContainer" id='contact'>
          <Toast message="Successfully send the mail" type="success" showToast={showToast} onClose={() => setShowToast(false)} />

      <div className="mainContactContainer">
        <div className="contactDesContainer">
          <div className="contactDesHeading">
            Fill the form to
            <span style={{ color: "#195776" }}> Contact us.</span>
          </div>
          <div className="contactDes">Connect with us effortlessly</div>
          <br></br>
          <div className="contact-details-container">
          <div className="contact-details">< div>Office:</ div>< div> Reg Byrne Community Centre Corner of Fyall Avenue and Darcy Road Wentworthville, NSW 2145<br/>Open only on Wednesday mornings.</ div></div>
          <div className="contact-details">< div>Postal Adress:</ div>< div> Australian Tamil Seniors Association P O Box 794 Wentworthville, NSW 2145</ div></div>
         <br/>
          <div className="contact-details">< div>ATSA Management Commitee: 2024/2025</ div></div>
          <div className="contact-details"><div>President:</div><div> Mr. P. Panchadcharam  <a href="tel:+610434006841">+610434006841</a> </div></div>
          <div className="contact-details"><div>Vice-President:</div><div> Mr. S. Poobalaretnam,  Mrs. S. Puspanayagam </div></div>
          <div className="contact-details"><div>Secretary:</div><div> Mr. E. Jeyaseelan  <a href="tel:+610481738530">+610481738530</a> </div></div>
          <div className="contact-details"><div>Assistant-Secretary:</div><div> Mr. S. Shanmugam </div> </div>
          <div className="contact-details"><div>Treasurer:</div><div> Mr. K. G. Sathiyasingan </div> </div>
          <div className="contact-details"><div>Assistant-Treasurer:</div><div> Mr. V. S. Ganeshan </div> </div>
          <br/>
          <div className="contact-details">< div>Executive Members:</ div></div>
          <div className="contact-details"><div></div><div> Mrs. Y. Kanagasingam  </div></div>
          <div className="contact-details"><div></div><div> Mrs. J. L. Edwin  </div></div>
          <div className="contact-details"><div></div><div> Mrs. I. Sivasubramaniam  </div></div>
          <div className="contact-details"><div></div><div> Mr. T. Sithivinayagalingam  </div></div>
          <div className="contact-details"><div></div><div> Mrs. L. Sundaralingam  </div></div>
          <div className="contact-details"><div></div><div> Mrs V. Vigneswaran  </div></div>
          <div className="contact-details"><div></div><div> Mrs M. Rajanayagam  </div></div>
 </div>
          
<br/>
       
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3315.3616586064345!2d150.9662997!3d-33.8029789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a2912d1b54ef%3A0xc74c527fab998720!2sReg%20Byrne%20Community%20Centre!5e0!3m2!1sen!2slk!4v1703335139477!5m2!1sen!2slk" width="auto" height="150" style={{ borderRadius: 10, border: 'none', maxWidth:200 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

        </div>
        <div className="inputBoxContainer">
          {/* <div>   <div className="iconDesContainer"> 
            <img className="contactIcon" src={messageIcon} alt="messageIcon" />
            <p className="iconDes"><a href="mailto:eajey@hotmail.com">eajey@hotmail.com</a>
            </p>
          </div>

          <div className="iconDesContainer">
            <img className="contactIcon" src={callIcon} alt="callIcon" />
            <p className="iconDes"><a href="tel:+61048738530">+61048738530</a></p>
          </div>

          <div className="iconDesContainer">
            <img
              className="contactIcon"
              src={locationIcon}
              alt="locationIcon"
            />
            <p className="iconDes"><a href="https://maps.app.goo.gl/bZwdPDtTSGSVDgjW9" target="blank">1 DREW Street (NSW). WESTMEAD, NSW 2145 </a>
            </p>
            <div className="map-preview-iframe" >

            </div>
          </div>
          </div> */}
          <div className="subInputBoxContainer">
            <CustomInput

              name="name"
              value={formValues.name}
              onChange={handleChange}
              onKeyUp={handleChange}
              onFocus={handleChange}
              placeholder='Name'
              error={formErrors.name}

            />
            <CustomInput

              name="email"
              value={formValues.email}
              onChange={handleChange}
              onKeyUp={handleChange}
              onFocus={handleChange}
              placeholder='Email Address'
              error={formErrors.email}

            />
            <CustomInput
              name="contact_no"
              value={formValues.contact_no}
              onChange={handleChange}
              onKeyUp={handleChange}
              onFocus={handleChange}
              error={formErrors.contact_no}
              type={'Number'}
              placeholder="Contact No" />
            <CustomTextArea placeholder="Message"

              name="message"
              value={formValues.message}
              onChange={handleChange}
              onKeyUp={handleChange}
              onFocus={handleChange}
              error={formErrors.message} />

            <CustomButton type="submit" label="Submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  name: state.contactus.name,
  email: state.contactus.email,
  message: state.contactus.message,
  contact_no: state.contactus.contact_no,

});
const mapDispatchToProps = (dispatch) => ({
  updateFormField: (field, value) => dispatch(updateFormField(field, value)),
  resetForm: () => dispatch(resetForm()),
  contactUsAsync: (formData) => dispatch(contactUsAsync(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);