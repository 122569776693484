import React, { useEffect } from "react";
import Services from "../container/Home/Services/Services";
import Events from "../container/Home/Events/Events";
import ContactUs from "../container/Home/ContactUs/ContactUs";
import Objective from "../container/Home/Objectives/Objectives";
import Community from "../container/Home/Community/Community";

const Home = () => {
  useEffect(() => {
    // Scroll to the "Contact" section when the component mounts
    const contactSection = document.getElementById('contact');
    if (contactSection && window.location.hash == '#contact') {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    const serviceSection = document.getElementById('service');
    if (serviceSection && window.location.hash == '#service') {
      serviceSection.scrollIntoView({ behavior: 'smooth' });
    }
    console.log(window.location.hash);
  }, []);

  return (
    <div id='home'>
      <Community />
      <Objective />
      <Services />
      <Events />
      <ContactUs id='contact' />

    </div>
  );
};

export default Home;
