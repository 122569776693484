import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { getAllGallerys } from '../../../redux/actions/galleryActions';
import { connect } from 'react-redux';
import CustomImagePreview from "../../../components/CustomImagePreview/CustomImagePreview";



const Gallery = ({gallery,getAllGallerys}) => {

  useEffect(() => {
    getAllGallerys();
}, [getAllGallerys]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;
  const openImagePreview = (image) => {
    setSelectedImage(image);
  };

 
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = gallery&&gallery.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="gallery-MainContainer">
    <p className="gallery-Heading" id="objective">
      Gallery
    </p>
    <div className="gallery-List">
      {currentImages.map((image, index) => (
        <img
          key={index}
          alt={`image-${index}`}
          src={image.url}
          onClick={() => openImagePreview(image.url)}
        />
      ))}
    </div>

    {/* {selectedImage && (
      <div className="image-preview-modal" onClick={closeImagePreview}>
        <img src={selectedImage} alt="preview" loading="lazy" />
      </div>
    )} */}
<CustomImagePreview image={selectedImage}/>
<div className="pagination">
  <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
    Previous
  </button>
  {Array.from({ length: Math.ceil(gallery.length / imagesPerPage) }).map((_, index) => (
    <button key={index} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
      {index + 1}
    </button>
  ))}
  <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(gallery.length / imagesPerPage)}>
    Next
  </button>
</div>

  </div>
  );
};

const mapStateToProps = (state) => ({
  gallery: state.gallery.galleries,
});

export default connect(mapStateToProps, { getAllGallerys })(Gallery);
